<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_box">
        <el-input size="small" placeholder="公司名称" v-model="listQuery.name" style="width:150px" @keyup.enter="getList"></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px" >
          <el-icon><Search /></el-icon> <span  >搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px -26px;width:700px" class="styleForm">
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
              <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择班级 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生'">   
              <selectClass v-model:deptId="listQuery.deptId" @success="getUserList"></selectClass>
            </el-form-item>
            <el-form-item label="选择人员 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生'">   
              <selectuser ref="selectuser" code="addCustomer" v-model:userId="listQuery.userId" @success="getUsersList"></selectuser>
            </el-form-item>
            <el-form-item label="所属税务总局 ：" :label-width="formLabelWidth"> 
              <selectcity v-model:citys="listQuery.districtCode"></selectcity>
            </el-form-item>
            <el-form-item label="税种采集状态 ：" :label-width="formLabelWidth"> 
              <selectCollectStatus v-model:collectStatus="listQuery.setUpStatusCj"></selectCollectStatus>
            </el-form-item>
            <el-form-item label="财务初始化状态 ：" :label-width="formLabelWidth"> 
              <selectJzStatus v-model:jzStatus="listQuery.setUpStatusJz"></selectJzStatus>
            </el-form-item>
            <el-form-item label="税务初始化状态 ：" :label-width="formLabelWidth"> 
              <selectPpStatus v-model:ppStatus="listQuery.setUpStatusPp"></selectPpStatus>
            </el-form-item>
          </el-form>
        </search>
        <!-- 悬浮导航 -->
      </div>
      <!-- <div  class="right_box">
        <el-button size="small" type="primary" plain v-if="tabName == 'first'" @click="gather">采集</el-button>
        <el-button size="small" type="primary" plain v-if="tabName == 'first'" @click="skipCollection" :loading="loading">跳过采集</el-button>
      </div> -->
    </div>
    <template v-if="tabName == 'first'">
      <contentTable ref="clientList" :tabName="tabName" @gather="gaOption" :listQuery="listQuery"></contentTable>
    </template>
  </div>
</template>

<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectuser from "@/components/Screening/selectuser";
import selectClass from "@/components/Screening/selectClass";

import selectcity from "@/components/Screening/selectcity";
import selectCollectStatus from "@/components/Screening/selectCollectStatus";
import selectJzStatus from "@/components/Screening/selectJzStatus";
import selectPpStatus from "@/components/Screening/selectPpStatus";
import contentTable from "./components/contentTable.vue";
// import { delCompany } from "@/api/company";
import { sendTask } from "@/api/collection"

export default {
  name: "addCustomer",
  components:{
    selecttaxtype,
    selectuser,
    selectcity,
    selectCollectStatus,
    selectJzStatus,
    selectPpStatus,
    contentTable,
    selectClass
  },
  data(){
    return{
      activeName: "first",
      tabName:'first',
      listQuery: {
        page: 1,
        limit: 20,
        status:"1",
        userId:0,
        userIds:[this.$store.getters['user/user'].userId]
      },
      formLabelWidth: '170px',
      loading:false,
      gatherId:{},  //表格ID
      userRoleName:this.$store.getters['user/user'].userRoleName
    }
  },
  activated() {
    this.activeName = this.tabName
    this.initBus()
    this.$bus.on("loadingClose", (val) => {
      this.loading = false
    });
  },
  methods:{
    initBus(){
      this.$bus.off("addCustomerUpdate")
      this.$bus.on("addCustomerUpdate", (val) => {
        this.getList()
      });
    },
    getList(){
      this.$refs.clientList.getList()
    },
    handleClick(tab, event) {
      this.listQuery.page = 1;
      this.listQuery.limit = 20;
      this.listQuery.status = "1"
      if (tab.props.label == "全部新增客户") {
        this.tabName = tab.props.name
      }else if (tab.props.label == "回收站") {
        this.tabName = tab.props.name
        this.listQuery.status = "9"
      }
    },
    companyfp(){
      this.$refs.clientList.companyfp()
    },
    automatic(){
      this.$refs.clientList.automatic()
    },
    skipCollection(){
      this.loading = true
      this.$refs.clientList.skipCollection()
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUsersList(e){
      this.listQuery.userIds = []
      if(this.listQuery.userId == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  margin-bottom: 10px;
  width: 70%;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.el-select{
  margin: 0px;
}
</style>