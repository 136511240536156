<template>
	<el-checkbox-group size="small" @change="changeValue" v-model="ppStatus">
		<el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
	</el-checkbox-group>
</template>
<script>
export default {
    name: 'selectPpStatus',
    props: {
			ppStatus: {
				type: Array,
				default: []
			}
    },
    data() {
			return {
				options: [
					{
						value: 1,
						label: '初始化成功',
					},
					{
						value: 6,
						label: '未初始化',
					},
					{
						value: 2,
						label: '部分初始化失败',
					},
          {
						value: 3,
						label: '初始化中',
					},
          {
						value: 4,
						label: '初始化失败',
					}
				],
				value: '',
			}
    },
    methods:{
			changeValue() {
				this.$emit("update:ppStatus", this.ppStatus)
				this.$emit("success", this.ppStatus)
			},
    }
}
</script>

<style>

</style>